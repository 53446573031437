import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "status": "draft",
  "path": "/sarplaninac-dogs/",
  "heroImage": "mountains.jpg",
  "title": "Sarplaninac Dogs",
  "description": "The Unknown Breed",
  "date": "2013-10-31T00:00:00.000Z",
  "tags": ["dogs", "personal"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The Šarplaninac or Yugoslavian Shepherd Dog (Serbian Cyrillic: Шарпланинац; formerly known as Ilirski Ovčar; Illyrian Shepherd dog) is a dog breed of the livestock guardian type named after the Šar Mountains. It is a molosser-type mountain dog.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.1875%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDBP/EABcBAAMBAAAAAAAAAAAAAAAAAAECAwT/2gAMAwEAAhADEAAAAdUrZstEIY//xAAbEAACAwADAAAAAAAAAAAAAAABAgADERIiMv/aAAgBAQABBQIImeY47LYZe2l2PL//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGq/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECERL/2gAIAQIBAT8BjbMs/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEhMQIRQf/aAAgBAQAGPwKjGLfSEaEiz//EABwQAQACAQUAAAAAAAAAAAAAAAEAMREhQVFhcf/aAAgBAQABPyG9a+R1CuDpDsS6i8FvErCJ/9oADAMBAAIAAwAAABBc3//EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxC2ij//xAAXEQEAAwAAAAAAAAAAAAAAAAABEBEh/9oACAECAQE/EGlpkH//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMVFx/9oACAEBAAE/EC8I9Sqr6GPqXuEzjHIA0dY3gHXrWqi4INBP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Sarplaninac Dog",
          "title": "Sarplaninac Dog",
          "src": "/static/1cac920b8843c043854ad82948c38850/72e01/sarplaninac.jpg",
          "srcSet": ["/static/1cac920b8843c043854ad82948c38850/e4a55/sarplaninac.jpg 256w", "/static/1cac920b8843c043854ad82948c38850/36dd4/sarplaninac.jpg 512w", "/static/1cac920b8843c043854ad82948c38850/72e01/sarplaninac.jpg 1024w", "/static/1cac920b8843c043854ad82948c38850/ac99c/sarplaninac.jpg 1536w", "/static/1cac920b8843c043854ad82948c38850/e1596/sarplaninac.jpg 2048w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`The Sar holds allegiance to only one master; everyone else is the enemy.`}<span>{`Some Dude Who Knows What's Up`}</span></p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAP/aAAwDAQACEAMQAAABfOhjcUoC/wD/xAAcEAACAAcAAAAAAAAAAAAAAAABAgAREhMiMTL/2gAIAQEAAQUCM6EuBymaQdt3/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAQQDAAAAAAAAAAAAAAAAAAIRInEQEjH/2gAIAQEABj8Ci7ktuiryqz//xAAbEAADAAIDAAAAAAAAAAAAAAAAITEBURARof/aAAgBAQABPyF6zVOoZjYupyOD3j//2gAMAwEAAgADAAAAEL8v/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/ENWP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxCDWn//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMVFxgeHx/9oACAEBAAE/EEbAopOl+oyEUGGK7igBps4uZ58ESeZG7PtP/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Sarplaninac Dog Book",
          "title": "Sarplaninac Dog Book",
          "src": "/static/0ea4d947b46d270ee5fbe3b089daec5d/6a068/macey_book.jpg",
          "srcSet": ["/static/0ea4d947b46d270ee5fbe3b089daec5d/e4a55/macey_book.jpg 256w", "/static/0ea4d947b46d270ee5fbe3b089daec5d/36dd4/macey_book.jpg 512w", "/static/0ea4d947b46d270ee5fbe3b089daec5d/6a068/macey_book.jpg 960w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      